import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./assets/style/common.scss";
import ElementPlus from 'element-plus';
import 'element-plus/theme-chalk/index.css';
import "animate.css";
const app = createApp(App);
app.use(router).use(ElementPlus);
app.mount("#app");
