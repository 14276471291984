<template>
  <div class="header" :class="{ 'before-header': tabbarBoxFixed }">
    <img src="../assets/image/logo.png" class="logo" @click="toIndex" />
    <div class="menuList">
      <div
        v-for="(item, index) in menuList"
        :key="index"
        @click="onMenu(index)"
        class="menu"
        :class="{ 'before-menu': menuIndex === index }"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="apply">
      <div @click="onApply" class="applystyle">申请试用</div>
      <div>|</div>
      <el-popover
        placement="top-start"
        popper-style="background:white;border:none;width:auto;height:auto;padding:0;borderRadius:25px"
        trigger="hover"
      >
        <div class="downloadApp">
          <img src="../assets/image/androidcode.png" />
          <img src="../assets/image/ioscode.png" />
        </div>
        <template v-slot:reference>
          <div class="blue">下载App</div>
        </template>
      </el-popover>

      <div class="blue" @click="onLogin">登录</div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, watch, onMounted } from "vue";
import { useRouter } from "vue-router";
export default {
  name: "Header",
  props: ["tabbarBoxFixed"],
  setup(props, context) {
    const onLogin = () => {
      window.open("https://ams.e-next.cn/#/login");
    };
    const onApply = () => {
      context.emit("customChange");
    };
    const router = useRouter();
    onMounted(() => {
      nowRouter();
    });
    const nowRouter = () => {
      const path = window.location.hash.slice(1, window.location.hash.length);
      var nowIndex = menuList.findIndex((item, index) => {
        console.log("path;", path, item.path);
        return item.path == path;
      });
      if (nowIndex >= 0) {
        menuIndex.value = nowIndex;
      }
    };

    var menuIndex = ref(0);

    const menuList = [
      {
        name: "首页",
        path: "/",
      },
      {
        name: "解决方案",
        path: "/solution",
      },
    ];
    const onMenu = (index) => {
      menuIndex.value = index;
      router.push(menuList[index].path);
    };
    const toIndex = () => {
      router.push("/");
    };
    watch(
      () => router.currentRoute.value,
      (newValue) => {
        document.documentElement.scrollTop = 0;
        nowRouter();
      },
      { immediate: true }
    );
    return {
      onLogin,
      onApply,
      menuList,
      menuIndex,
      onMenu,
      toIndex,
    };
  },
};
</script>
<style scoped lang="scss">
.header {
  width: 100%;
  height: 70px;
  background: white;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  transition: 0.5s;
  display: flex;
  padding: 0 0.6rem;
  box-sizing: border-box;
  justify-content: space-between;
}
.before-header {
  position: fixed;
  left: 0;
  top: 0;
  background: white;
  transition: 0.5s;
  z-index: 50;
}
.logo {
  width: 0.8rem;
  display: block;
  margin: auto 0;
  cursor: pointer;
}

.apply {
  display: flex;
  font-size: 16px;
  font-family: pingfangB;
  line-height: 70px;
  height: 70px;
  div {
    margin: 0 10px;
    line-height: 70px;
  }
  .blue {
    color: #2435eb;
    cursor: pointer;
  }
}
.downloadApp {
  width: 2rem;
  height: 1.6rem;
  background: url("../assets/image/hoverbg.png");
  background-size: 100% 100%;
  border-radius: 20px;
  padding: 10px 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  img {
    width: 0.6rem;
    height: 0.6rem;
    display: block;
    margin: 10px 20px;
  }
}
.applystyle {
  cursor: pointer;
}
.menuList {
  display: flex;
  position: absolute;
  top: 25px;
  right: 25%;
  font-size: 16px;
  font-family: pingfangB;
  cursor: pointer;
  .menu {
    margin-right: 50px;
    border-bottom: 4px solid transparent;
    height: 28px;
    padding: 0 10px;
    &:hover {
      border-bottom: 4px solid #2144f9;
      // border-image-source: linear-gradient(to right, #1989fa, #44916c);
      // border-image-slice: 1;
    }
  }
  .before-menu {
    margin-right: 50px;
    // border-bottom: 4px solid #4974ff;
    border-bottom: 4px solid #2144f9;
    // border-image-source: linear-gradient(to right, #1989fa, #44916c);
    // border-image-slice: 1;
  }
}
</style>
