<template>
  <div class="footer" v-if="showFooter">
    <div class="footer-container">
      <div class="left">
        <div class="logo-box">
          <img src="~@/assets/image/whitelogo.png" class="footer-logo" />
        </div>
        <div class="item-box" style="opacity:0">
          <p class="font-grey">业务拓展</p>
          <p @click="onMenu('/business')">业务介绍</p>
          <p @click="onMenu('/introduction')">产品介绍</p>
        </div>
        <div class="item-box third-box">
          <p @click="openTkzy">天科智友</p>
          <p>|</p>
          <p @click="openZkyc">中科医创</p>
        </div>
      </div>
      <div class="right">
        <p>科技助力市场</p>
        <p>数智成就未来</p>
      </div>
    </div>
    <div class="bottom">
      版权所有 © 天津天科智友科技有限公司  津ICP备2023002978号-3
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { ref, watch } from "vue";
export default {
  name: "Footer",
  props: {
    msg: String,
  },
  setup() {
    const router = useRouter();
    const onMenu = (path) => {
      router.push(path);
    };
    const openZkyc = () => {
      window.open("https://www.zkyctec.com/");
    };
    const openTkzy = () =>{
      window.open("https://www.tickey.cn/");
    }
    var showFooter = ref(true);
    watch(
      () => router.currentRoute.value,
      (newValue) => {
        console.log("footer:", newValue);
        if (newValue.path == "/contactus") {
          showFooter.value = false;
        } else {
          showFooter.value = true;
        }
      },
      { immediate: true }
    );
    return {
      onMenu,
      openZkyc,
      showFooter,
      openTkzy,
    };
  },
};
</script>
<style scoped lang="scss">
.footer {
  width: 100%;
  box-sizing: border-box;
  padding: 0.469rem /* 90/192 */ 0.911rem /* 175/192 */;
  padding: 70px 100px 30px;
  background: #3a4c69;
  color: #fff;
  font-family: pingfangM;
  // margin-top: 10px;
  p {
    margin: 0;
    font-size: 16px /* 24/192 */;
  }
  .font-grey {
    color: #fff;
  }
  .footer-container {
    display: flex;
    // margin-bottom: 0.458rem /* 88/192 */;
    margin-bottom: 40px;
    .left {
      width: 60%;
      display: flex;
      .logo-box {
        width: 35%;
        font-family: pingfangB;
        // text-align: left;
        p {
          font-size: 20px;
        }
        img {
          width: 100px /* 150/192 */;
          height: 100px /* 150/192 */;
        }
      }
      .item-box {
        // text-align: left;
        width: auto;
        p {
          // margin-bottom: 0.13rem /* 25/192 */ /* 30/192 */;
          margin-bottom: 16px;
          margin: 0 20px;
          cursor: pointer;
        }
        p:first-child {
          margin-bottom: 20px;
          // margin-bottom: 0.156rem; /* 30/192 */
        }
      }
      .third-box {
        width: auto;
        display: flex;
      }
    }
  
  }
  .bottom {
    width: 100%;
    text-align: center;
    font-size: 14px /* 18/192 */;
  }
}
  .right {
      width: 40%;
      text-align: left;
      justify-content: flex-start;
      padding-left: 0.781rem /* 150/192 */;
      box-sizing: border-box;
      p{
      font-size: 0.2rem !important;
      font-style: oblique;
      }
    }
</style>
