<template>
  <div style="matgin-bottom: 10px">
    <div class="header"></div>
    <div class="main-header">
      <img class="main-header-img" src="../assets/image/mainbg.png" alt="" />
      <div class="main-title1 wow bounceInLeft">数智化营销平台</div>
      <div class="main-title2 wow bounceInLeft">
        业财融合 · O2O联动 · 穿透产研销
      </div>
      <div class="apply wow bounceInLeft" @click="onApply">申请试用</div>

      <img src="../assets/image/mainleve.png" class="main-leve wow zoomIn" />
    </div>

    <!-- 营销服务 -->

    <div class="main-serve">
      <div class="title wow zoomIn">创新数智化营销服务</div>
      <div style="width: 100%; displat: flex">
        <img class="menu" src="../assets/image/menu.png" />
      </div>
      <div class="content">
        <div class="public-content wow zoomIn">
          <img class="public-image" src="../assets/image/chart1.png" />
          <div class="public-title">自由职业营销服务</div>
          <div class="public-view">
            赋能B2C和C2C服务场景，为自由职业者聚集资源和能力，强化C端服务竞争优势，实现智能化精准营销。
          </div>
        </div>
        <div class="public-content wow zoomIn">
          <img class="public-image" src="../assets/image/chart2.png" />
          <div class="public-title">业财融合风控服务</div>
          <div class="public-view">
            集成业内顶级财务和业务系统，业财融合前置风险管控，AI深度学习智能识别风险点，构建数智风险管控模型。
          </div>
        </div>
        <div class="public-content wow zoomIn">
          <img class="public-image" src="../assets/image/chart3.png" />
          <div class="public-title">穿透式产业链服务</div>
          <div class="public-view">
            构建“研产销”和“产学研”双向循环，实现信息、资源和技术的穿透，再生态产业链的任意点可形成聚集型竞争优势。
          </div>
        </div>
      </div>
    </div>

    <!-- 场景推广 -->

    <div class="main-promotion">
      <div class="promotion-left wow bounceInLeft">
        <div class="left-title1">全方位多场景推广能力</div>
        <div class="left-title2">多病种学术推广 + 临床科研推广</div>
        <div class="left-title2">基层医疗机构 + 三甲医院 + 科研院校</div>
        <img class="left-menu" src="../assets/image/menu.png" />
      </div>
      <div class="line"></div>
      <div class="promotion-right">
        <div class="adm-setting adm-box">
          <img
            class="settingimg wow zoomIn"
            data-wow-delay="0.5s"
            src="../assets/image/admsetting.png"
          />
          <div class="adm-terred wow bounceInUp" data-wow-delay="0.5s">
            <img class="terred-img" src="../assets/image/pbm.png" />
            <div class="terred-text">科创云医慢病管理系统</div>
          </div>
          <div data-wow-delay="0.5s" class="terred-view wow bounceInUp">
            基于人工智能帮助科室快速实现慢病患者的常规随访、体检通知、健康宣教、体征监测，辅助医护及公卫人员的日常诊疗护理和数据维护工作，为医院向患者提供多样化诊疗服务创造条件。
          </div>
        </div>
        <img
          class="addimg wow zoomIn"
          data-wow-delay="0.5s"
          src="../assets/image/add.png"
        />
        <div class="adm-serve adm-box">
          <img
            class="serveimg wow zoomIn"
            data-wow-delay="0.5s"
            src="../assets/image/admserve.png"
          />
          <div class="adm-terred wow bounceInUp" data-wow-delay="0.5s">
            <img class="terred-img2" src="../assets/image/thoth.png" />
            <div class="terred-text">临床科研一体化信息平台</div>
          </div>
          <div data-wow-delay="0.5s" class="terred-view wow bounceInUp">
            以临床数据为中心，标准化建库、自由创建DVP、自主创建eCRF、自动核查、医学编码等全方位全流程覆盖，满足各类复杂临床试验研究设计，
            提供注册研究、上市后研究、RCT研究、IIT研究、RWS研究一体化的解决方案，帮助药械企业、医研人员等全面提升研究效率、加速产品上市、改善临床服务，助力科研成果高效产出。
          </div>
        </div>
      </div>
    </div>

    <!-- 创新案例 -->
    <div class="project-list">
      <div class="title wow zoomIn">项目案例</div>
      <div>
        <img class="menu" src="../assets/image/menu.png" />
      </div>
      <div class="box wow zoomIn">
        <div class="ul">
          <div class="li first-li">
            <img class="first-project" src="../assets/image/project1.png" />
            <div class="tag">学术推广</div>

            <div class="tag-title">叶酸介导上皮组<br />织特殊染色液</div>

            <div class="firsttag-content">
              <p>无痛、无创、避免感染，操作简单、适合基层，30秒出结果</p>
              <p>独家发明专利，稳定性强</p>
              <p>无需生化检验、无需专门设备</p>
            </div>

            <div class="tag-footer">
              <img src="../assets/image/yuhang.png" />
              <div class="tag-footer-text">辽宁钰航生物医疗科技有限公司</div>
            </div>
          </div>
          <div class="li">
            <img class="other-project" src="../assets/image/project2.png" />
            <div class="tag">科研推广</div>
            <div class="tag-title">
              蛭蛇通络胶囊产品适应症拓展及<br />机理研究l期
            </div>

            <div class="tag-content">
              <p>
                开展蛭蛇通络胶囊“中医证候评分与脑卒中患者临床神经功能缺损程度评分（1995
                版）<br />的相关性研究”
              </p>
              <p>开展蛭蛇通络胶囊作用心梗患者的药物机理研究</p>
            </div>
            <div class="tag-footer">
              <img
                class="shanxijianmin"
                src="../assets/image/shanxijianmin.png"
              />
            </div>
          </div>
          <div class="li">
            <img class="other-project" src="../assets/image/project2.png" />
            <div class="tag">科研推广</div>
            <div class="tag-title">心脉隆注射液产品适应症拓展研<br />究l期</div>
            <div class="tag-content">
              <p>
                对心脉隆临床实证依据进行电子化、数字化处理，形成心脉隆循证医学研究数据库
              </p>
              <p>
                开展心脉隆注射液“中医证候评分与检测指标：BNP/NT-proBNP、LVEF、LVEDD、6MWD、尿量等的相关性研究”
              </p>
              <p>开展“心脉隆注射液作用手术前患者应用的临床实证研究”</p>
            </div>
            <div class="tag-footer">
              <img src="../assets/image/tengyao.png" />
            </div>
          </div>
        </div>
      </div>

      <div class="project-footer wow bounceInLeft">即刻体验亿来数智</div>
      <div class="apply-btn wow bounceInRight" @click="onApply">申请试用</div>
    </div>
  </div>
</template>

<script>
import { inject, ref, onMounted, nextTick } from "vue";
import * as echarts from "echarts";
import { useRouter } from "vue-router";
import { WOW } from "wowjs";
export default {
  name: "Home",
  props: ["tabbarBoxFixed"],
  setup(props, context) {
    const onApply = () => {
      console.log("1111");
      context.emit("customChange");
    };
    // 动画
    nextTick(() => {
      const wow = new WOW({
        live: false,
      });
      wow.init();
    });
    return {
      onApply,
    };
  },
};
</script>
<style scoped lang="scss">
.header {
  width: 100%;
  height: 70px;
}
.main-header {
  width: 100%;
  height: 4.5rem;
  // background: url("../assets/image/mainbg.png");
  background: #143ce5;
  background-size: 100% 100%;
  position: relative;
  .main-header-img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 4.5rem;
  }
}
.main-title1 {
  position: absolute;
  left: 0.8rem;
  top: 0.9rem;
  color: white;
  font-family: pingfang;
  font-size: 0.31rem;
  letter-spacing: 10px;
}
.main-title2 {
  position: absolute;
  left: 0.8rem;
  top: 1.45rem;
  color: white;
  font-family: pingfang;
  font-size: 22px;
  letter-spacing: 4px;
}
.apply {
  width: 1rem;
  height: 0.24rem;
  text-align: center;
  line-height: 0.24rem;
  height: 0.24ren;
  background: white;
  color: #2144f9;
  font-size: 18px;
  cursor: pointer;
  position: absolute;
  left: 0.8rem;
  font-family: pingfangB;
  top: 2rem;
  border-radius: 25px;
}
.main-leve {
  width: 4.93rem;
  height: 3.7rem;
  position: absolute;
  right: 0.3rem;
  top: 0.3rem;
}
.main-serve {
  width: 100%;
  height: 3.21rem;
  background: #f6f6f6;

  .content {
    width: 8.3rem;
    height: 1.5rem;
    display: flex;
    margin: 0.41rem auto 0 auto;
    background: transparent;
    display: flex;
    justify-content: space-between;
    .public-content {
      width: 2.46rem;
      height: 1.49rem;
      background: white;
      box-shadow: 0px 10px 15px 0px rgba(234, 234, 234, 0.75);
      border-radius: 5px;
    }
    .public-image {
      width: 0.27rem;
      height: 0.25rem;
      display: block;
      margin: 0.2rem auto 0.1rem auto;
    }
    .public-title {
      font-size: 0.125rem;
      color: #000;
      width: 100%;
      text-align: center;
      font-family: pingfangB;
    }
    .public-view {
      text-align: left;
      box-sizing: border-box;
      padding: 0 0.25rem;
      margin-top: 0.1rem;
      box-sizing: border-box;
      font-size: 14px;
      line-height: 0.13rem;
      color: #666;
      font-family: pingfang;
    }
  }
}
.main-promotion {
  width: 100%;
  height: 4.6rem;
  background: white;
  display: flex;
  position: relative;
  .promotion-left {
    padding: 1.07rem 0.2rem 0 0.82rem;
    .left-title1 {
      font-family: pingfang;
      text-align: left;
      font-weight: bold;
      margin-bottom: 0.2rem;
      font-size: 0.23rem;
    }
    .left-title2 {
      font-family: pingfang;
      font-size: 0.11rem;
      margin-top: 0.05rem;
      text-align: left;
    }
    .left-menu {
      width: 0.8rem;
      height: 14px;
      float: left;
      margin-left: -0.1rem;
      margin-top: 0.2rem;
    }
  }
  .line {
    width: 1px;
    height: 85%;
    margin: auto 0;
    background: #f1f1f1;
  }
  .promotion-right {
    display: flex;
    margin-left: 0.3rem;
    width: 6rem;
    .adm-box {
      width: 4rem;
    }
    .adm-setting {
      margin: auto 0;
    }
    .addimg {
      margin: auto 0.514rem;
    }
    .adm-serve {
      margin: auto 0;
    }
  }
  .terred-view {
    color: #333333;
    font-size: 14px;
    width: 2.5rem;
    // padding: 0 0.3rem;
    box-sizing: border-box;
    position: absolute;
    text-align: left;
  }
  .adm-terred {
    width: 1.7rem;
    height: 0.29rem;
    border-radius: 25px;
    line-height: 0.29rem;
    margin: 0.25rem auto;
    display: flex;
    font-size: 0.1rem;
    color: #ffffff;
    background: #2144f9;

    .terred-text {
      flex: 1;
      text-align: left;
    }
    .terred-img {
      width: 0.2rem;
      height: 0.2rem;
      display: block;
      margin: auto 20px;
    }
    .terred-img2 {
      width: 0.1rem;
      height: 0.2rem;
      display: block;
      margin: auto 30px auto 20px;
    }
  }
  .settingimg {
    width: 1.67rem;
    height: 1.92rem;
    display: block;
    margin: 0 auto;
  }
  .serveimg {
    width: 1.98rem;
    height: 1.92rem;
    display: block;
    margin: 0 auto;
  }
  .addimg {
    width: 0.29rem;
    height: 0.29rem;
  }
}
.title {
  width: 100%;
  font-family: pingfang;
  font-weight: bold;
  color: #000000;
  text-align: center;
  font-size: 0.23rem;
  padding-top: 0.2rem;
  box-sizing: border-box;
}
.menu {
  width: 0.8rem;
  display: block;
  height: 14px;
  margin: 15px auto 0 auto;
}
.project-list {
  width: 100%;
  height: 6rem;
  background: #f6f6f6;
  .box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 8.33rem;
    height: 3.43rem;
    margin: 0.27rem auto 0 auto;
  }

  .box .ul {
    width: 100%;
    height: 100%;
    /* 超出部分隐藏 */
    overflow: hidden;
    /* 过渡为0.3秒 */
    transition: all 0.3s;
    display: flex;
    justify-content: space-between;
  }

  .first-li {
    width: 3.98rem !important;
    background: #0c46f2 !important;
  }

  .box .ul .li {
    /* 相对定位 */
    position: relative;
    border-radius: 30px;
    list-style-type: none;
    width: 2.04rem;
    height: 3.43rem;
    background: #146cf0;
    overflow: hidden;
    /* 过渡为0.5秒 */
    transition: all 0.5s;
    .tag {
      width: 0.66rem;
      height: 0.187rem;
      line-height: 0.187rem;
      font-size: 0.09rem;
      text-align: center;
      background: transparent;
      position: absolute;
      left: 0.26rem;
      top: 0.26rem;
      border: 2px solid #fff;
      font-family: pingfang;
      color: white;
      border-radius: 25px;
    }
    .tag-title {
      font-size: 0.23rem;
      color: white;
      font-family: pingfang;
      text-align: left;
      font-weight: bold;
      position: absolute;
      left: 0.26rem;
      top: 0.72rem;
    }
    .tag-content {
      font-size: 0.09rem;
      color: white;
      font-family: pingfang;
      text-align: left;
      position: absolute;
      left: 0.26rem;
      top: 1.4rem;
      opacity: 0;
    }
    .firsttag-content {
      font-size: 0.09rem;
      color: white;
      font-family: pingfang;
      text-align: left;
      position: absolute;
      left: 0.26rem;
      top: 1.4rem;
      opacity: 1;
    }
    .first-project {
      width: 2.64rem;
      height: 2.93rem;
      position: absolute;
      right: 0;
      bottom: 0;
      transition: all 0.5s !important;
    }
    .other-project {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 2.04rem !important;
      height: 1.93rem !important;
      transition: all 0.5s !important;
    }
    .tag-footer {
      width: auto;
      height: 0.34rem;
      background: white;
      position: absolute;
      overflow: hidden;
      left: 0.26rem;
      bottom: 0.2rem;
      display: flex;
      padding: 0 0.1rem;
      box-sizing: border-box;
      border-radius: 10px;
      transform: all 0.5s;
      .tag-footer-text {
        font-size: 0.11rem;
        line-height: 0.34rem;
        font-family: pingfang;
        font-weight: bold;
      }
      .shanxijianmin {
        width: 0.9rem !important;
        height: 0.15rem;
      }
      img {
        width: 0.63rem;
        margin: auto 0;
      }
    }
  }

  /* 鼠标移入时让所有的li宽度变为90px */

  .box .ul:hover .li {
    background: #3e70ee !important;
    width: 2.04rem !important;
    height: 3.43rem;
    .first-project {
      width: 2.04rem !important;
      height: 1.93rem !important;
      transition: all 0.5s !important;
    }
    .other-project {
      width: 2.04rem !important;
      height: 1.93rem !important;
      transition: all 0.5s !important;
    }
    .tag-content {
      font-size: 0.09rem;
      color: white;
      font-family: pingfang;
      text-align: left;
      position: absolute;
      left: 0.26rem;
      top: 1.4rem;
      opacity: 0 !important;
    }
    .firsttag-content {
      font-size: 0.09rem;
      color: white;
      font-family: pingfang;
      text-align: left;
      position: absolute;
      left: 0.26rem;
      top: 1.4rem;
      opacity: 0 !important;
    }
    .tag-footer-text {
      opacity: 0;
      display: none;
    }
  }

  /* 鼠标移入时让当前的宽度变为640px */
  .box .ul .li:hover {
    background: #2a43ef !important;
    width: 3.98rem !important;
    height: 3.43rem;
    .first-project {
      width: 2.64rem !important;
      height: 2.93rem !important;
      transition: all 0.5s !important;
    }
    .other-project {
      width: 2.64rem !important;
      height: 2.93rem !important;
      transition: all 0.5s !important;
    }
    .tag-content {
      font-size: 0.09rem;
      color: white;
      font-family: pingfang;
      text-align: left;
      position: absolute;
      left: 0.26rem;
      top: 1.4rem;
      opacity: 1 !important;
      transition: all 2s !important;
      display: block;
    }
    .firsttag-content {
      font-size: 0.09rem;
      color: white;
      font-family: pingfang;
      text-align: left;
      position: absolute;
      left: 0.26rem;
      top: 1.4rem;
      opacity: 1 !important;
      transition: all 2s !important;
      display: block;
    }
    .tag-footer-text {
      font-size: 0.11rem;
      line-height: 0.34rem;
      font-family: pingfang;
      font-weight: bold;
      opacity: 1;
      display: block;
    }
  }
}
.project-footer {
  width: 100%;
  text-align: center;
  font-size: 0.18rem;
  font-family: pingfang;
  font-weight: bold;
  margin-top: 0.41rem;
}
.apply-btn {
  width: 1.05rem;
  height: 0.3rem;
  line-height: 0.3rem;
  text-align: center;
  background: #2144f9;
  cursor: pointer;
  color: white;
  margin: 0.2rem auto;
  font-size: 0.11rem;
  border: 1px solid #fff;
  border-radius: 30px;
  font-family: pingfangB;
  &:hover {
    background: white;
    color: #2144f9;
    border: 1px solid #2144f9;
  }
}




</style>
