const { createRouter, createWebHashHistory } = require("vue-router");
import Home from "./views/Home";
import Solution from "./views/Solution";

// import Introduction from "./views/Introduction";
// import Business from "./views/Business";
// import ContactUs from "./views/ContactUs";
const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    { path: "/", component: Home },
    { path: "/solution", component: Solution },
    // { path: "/introduction", component: Introduction },
    // { path: "/business", component: Business },
    // { path: "/contactus", component: ContactUs },
  ],
});

export default router;
