<template>
  <div class="solution-wrapper">
    <div class="top">
      <div class="triangle"></div>
      <p class="title wow flipInX">三大平台构筑医药行业研产销一体化服务能力</p>
      <p class="sub-title wow flipInY">
        发挥“医”的核心价值，以“服务”为中心，强化研产销产业链竞争优势
      </p>
      <div class="product-box">
        <div class="center-box">
          <div class="kcyy wow bounceInLeft">
            <img src="../assets/image/solution/kcyy_logo.png" />
          </div>
          <div class="thoth wow bounceInRight">
            <img src="../assets/image/solution/Thoth_logo.png" />
          </div>
          <div class="enext wow bounceInDown">
            <img src="../assets/image/solution/enext_logo.png" />
          </div>
        </div>
        <div class="kcyy-con pro-content wow slideInLeft">
          <p class="tit">科创云医慢病管理平台</p>
          <p class="detail">
            以患者为中心的院外健康管理系统，可打通区域
            内的各级医疗机构及基层公共卫生系统，主要功
            能包括健康档案、医患交流、健康监测、用药监测、AI随访等。
          </p>
        </div>
        <div class="thoth-con pro-content wow slideInRight">
          <p class="tit">CRO临床科研平台</p>
          <p class="detail">
            以临床研究为中心，提供注册研究、上市后研究、RCT
            研究、IIT研究、RWS研究一体化的解决方案，帮助药械
            企业、医研人员全面提升研究效率。包括EDC、IWRS、MedCoding、PV等多个子系统，是临床研究的专用平台。
          </p>
        </div>
        <div class="enext-con pro-content wow slideInDown">
          <p class="tit">亿来数智数字化营销平台</p>
          <p class="detail">
            新一代数字营销平台，集合了渠道管理、终端管理、物资
            管理、推广管理、绩效管理、结算中心、财税服务等一体化功能。
          </p>
        </div>
      </div>
    </div>

    <!--行业风向-->
    <div class="hyfx">
      <p class="tit wow flipInY">行业风向</p>
      <div class="con wow zoomIn">
        <div class="left">
          <p class="jinshui">金税四期</p>
          <p class="shupiao">数</p>
          <div class="fx"></div>
          <p class="shupiao shupiao2">票</p>
          <p class="jinshui jinshui2">金税三期</p>
        </div>
        <div class="right">
          <p class="rgt-tit">“以数治税”背景下企业的财税管理挑战</p>
          <div class="list-box">
            <div class="left-list">
              <p class="list-title">发票作为审查企业合规的关键工具</p>
              <p class="item">
                税务监查要求提高，从 “三流一致”升级 为“四流一致”；
              </p>
              <p class="item">在线处理发票对企业日常运营效率提出了新的要求；</p>
              <p class="item">
                电子发票使得企业的业务运营信息在税 务面前“透明化”。
              </p>
            </div>
            <div class="rgt-list">
              <p class="list-title">设计合理合法的业务流程，提升企业利润</p>
              <p class="item">
                税务系统主动监控、预警，企业需规范 业务流程，减低企业税务成本；
              </p>
              <p class="item">
                税务、工商、社保、银行系统实现共享， 需优化企业人员结构；
              </p>
              <p class="item">
                运用数据分析企业的竞争优势，提升业 务创新能力。
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--我们的解决方案-->
    <div class="solutions">
      <p class="tit wow flipInY">我们的解决方案</p>
      <div class="content-box">
        <div class="left wow zoomIn">
          <div class="you-suite">
            <img class="logo" src="../assets/image/solution/logo.png" />
            <img class="plus" src="../assets/image/solution/plus.png" />
            <img class="you" src="../assets/image/solution/YonSuite.png" />
          </div>
          <p class="sub-tit">医疗产业链业财融合</p>
          <p class="sub-tit">[数智风控解决方案]</p>
          <div class="advantage">
            <div class="time">省时</div>
            <div class="labor">省力</div>
            <div class="worry">省心</div>
            <div class="money">省钱</div>
          </div>
        </div>
        <div class="table wow zoomIn">
          <div class="item-box" style="padding-top: 0.25rem">
            <img
              class="item-logo"
              src="../assets/image/solution/cwy_01.png"
              style="padding-top: 0.26rem"
            />
            <p class="item-tit">实时会计</p>
            <p class="item-detail">
              业务发生后实时执行财务会计与管理会计的多核算目的核算，实时财务报表与管理分析，实时反馈业务系统进行调整控制。
            </p>
          </div>
          <div class="item-box" style="padding-top: 0.25rem">
            <img
              class="item-logo"
              src="../assets/image/solution/cwy_03.png"
              style="padding-top: 0.26rem"
            />
            <p class="item-tit">精准税务</p>
            <p class="item-detail">
              以业财税金一体化、全流程应用，全税种管理与核算，税企直连自动化和税务风险评估为核心的创新应用。
            </p>
          </div>
          <div class="item-box">
            <img class="item-logo" src="../assets/image/solution/cwy_02.png" />
            <p class="item-tit">智能财务</p>
            <p class="item-detail">
              全面采用图像识别、语音识别、机器学习、RPA、VPA等各种智能技术，提升财务运营效率。
            </p>
          </div>
          <div class="item-box">
            <img class="item-logo" src="../assets/image/solution/cwy_04.png" />
            <p class="item-tit">敏捷财资</p>
            <p class="item-detail">
              以企业现金流活动管理闭环为主线，提供企业流动性、资本运营、投融资和风险管理，将金融服务嵌入企业经营活动，银企联云新模式打通与银行的连接通道。
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- 核心价值 -->
    <div class="value-wrapper">
      <div class="tit-box wow bounceInUp">
        <p class="tit">核心价值</p>
        <p class="detail">
          提升业财协同效率，加强业财融合深度，提高企业数据质量，辅助企业经营决策，管理模式创新，商业模式创新。
        </p>
      </div>
      <p class="desc wow zoomIn" style="padding-top: 2.25rem">
        在数字经济时代，亿来数智+YonSuite，
      </p>
      <p class="desc wow zoomIn">
        以“业财税深度融合”为新起点，助力企业在新赛道腾飞！
      </p>
    </div>
  </div>
</template>
<script>
import { inject, ref, onMounted, nextTick } from "vue";
import * as echarts from "echarts";
import { useRouter } from "vue-router";
import { WOW } from "wowjs";
export default {
  name: "Solution",
  setup(props, context) {
    // 动画
    nextTick(() => {
      const wow = new WOW({
        live: false,
      });
      wow.init();
    });
  },
};
</script>
<style scoped lang="scss">
p {
  margin: 0;
}
.solution-wrapper {
  // margin-top: 70px;
  font-size: pingfang;
  .top {
    height: 5.536rem;
    background: url(../assets/image/solution/top_bg.png);
    background-size: 100% 100%;
    // background-color: #e4eaf7;
    box-sizing: border-box;
    padding-top: 70px;

    .title {
      text-align: center;
      font-size: 0.25rem;
      margin-top: 0.443rem;
    }
    .sub-title {
      text-align: center;
      font-size: 0.104rem;
      margin-top: 0.25rem;
    }
    .product-box {
      padding-top: 0.469rem;
      position: relative;
      width: 100%;
      height: 4.036rem;
      text-align: center;
      box-sizing: border-box;
      // display: flex;
      // justify-content: center;
      .center-box {
        width: 2.917rem;
        height: 2.672rem;
        position: relative;
        left: 50%;
        margin-left: -1.4585rem;
        .kcyy {
          position: absolute;
          top: 0.63rem;
          left: 0;
          width: 1.688rem;
          height: 1.589rem;
          background: url(../assets/image/solution/kcyy_bg.png);
          background-size: 100% 100%;
          img {
            position: absolute;
            top: 0.37rem;
            right: 0.146rem;
            width: 0.615rem;
            height: 0.885rem;
          }
        }
        .thoth {
          position: absolute;
          top: 0;
          right: 0;
          width: 1.719rem;
          height: 1.427rem;
          background: url(../assets/image/solution/thoth_bg.png);
          background-size: 100% 100%;
          img {
            position: absolute;
            bottom: 0.089rem;
            left: 0.51rem;
            width: 0.516rem;
            height: 0.635rem;
          }
        }
        .enext {
          position: absolute;
          top: 1.427rem;
          right: 0.323rem;
          width: 1.396rem;
          height: 1.411rem;
          background: url(../assets/image/solution/enext_bg.png);
          background-size: 100% 100%;
          img {
            position: absolute;
            top: 0.073rem;
            left: 0.411rem;
            width: 0.641rem;
            height: 0.604rem;
          }
        }
      }
      .pro-content {
        text-align: left;
        position: absolute;
        .tit {
          font-size: 0.167rem;
          font-weight: bold;
          color: #333333;
          padding-bottom: 0.094rem;
          border-bottom: 1px solid #333;
        }
        .detail {
          margin-top: 0.094rem;
          font-size: 0.094rem;
          line-height: 1.5;
          color: #343434;
        }
      }
      .kcyy-con {
        left: 1.161rem;
        top: 1.099rem;
        width: 2.135rem;
      }
      .thoth-con {
        right: 1.161rem;
        top: 0.469rem;
        width: 2.135rem;
      }
      .enext-con {
        right: 1.561rem;
        bottom: 0.549rem;
        width: 2.135rem;
      }
    }
  }
  .hyfx {
    width: 100%;
    height: 4.615rem;
    background: url(../assets/image/solution/hyfx-bg.png);
    background-size: 100% 100%;
    box-sizing: border-box;
    padding-top: 0.365rem;
    .tit {
      font-size: 0.25rem;
      text-align: center;
      margin-bottom: 0.365rem;
    }
    .con {
      margin: 0 1rem 0.391rem;
      display: flex;
      height: 3.188rem;
      .left {
        width: 30%;
        background: url(../assets/image/solution/hyfx-left.png);
        background-size: 100% 100%;
        box-sizing: border-box;
        padding: 0.25rem;
        border-top-left-radius: 0.052rem;
        border-bottom-left-radius: 0.052rem;
        .jinshui {
          font-size: 0.219rem;
          font-weight: 700;
          color: #ffffff;
          line-height: 0.25rem;
          text-align: left;
        }
        .jinshui2 {
          text-align: right;
        }
        .shupiao {
          font-size: 0.563rem;
          font-weight: 700;
          color: #ffffff;
          margin-left: 0.25rem;
          margin-top: 0.1rem;
        }
        .shupiao2 {
          margin-right: 0.2rem;
          text-align: right;
        }
        .fx {
          width: 0.432rem;
          height: 0.469rem;
          background: url(../assets/image/solution/fx.png);
          background-size: 100% 100%;
          margin: 0 auto;
        }
      }
      .right {
        width: 70%;
        background: #fff;
        padding: 0.417rem 0.365rem;
        border-top-right-radius: 0.052rem;
        border-bottom-right-radius: 0.052rem;
        .rgt-tit {
          font-size: 0.188rem;
          color: #010101;
          margin-bottom: 0.285rem;
        }
        .list-box {
          display: flex;
          .list-title {
            font-size: 0.125rem;
            font-weight: 700;
            color: #d41926;
            line-height: 0.188rem;
          }
          .left-list {
            margin-right: 0.302rem;
          }
          .item {
            position: relative;
            padding-left: 0.2rem;
            font-size: 0.125rem;
            color: #010101;
            line-height: 0.156rem;
            margin-top: 0.219rem;
            &::before {
              position: absolute;
              left: 0;
              top: 0.05rem;
              content: "";
              width: 0.104rem;
              height: 0.104rem;
              background-color: #d51c28;
              transform: rotate(45deg);
              display: block;
            }
          }
        }
      }
    }
  }
  .solutions {
    width: 100%;
    height: 6.365rem;
    background: url(../assets/image/solution/solutions-bg.png) no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
    padding-top: 0.365rem;
    .tit {
      font-size: 0.25rem;
      text-align: center;
      margin-bottom: 0.599rem;
    }
    .content-box {
      display: flex;
      padding: 0 1.021rem;
      .left {
        width: 2.528rem;
        margin-right: 0.557rem;
        .you-suite {
          width: 2.328rem;
          height: 0.547rem;
          background: #f7f7f7;
          border: 5px solid #dddddd;
          border-radius: 20px;
          padding: 0.078rem 0.13rem;
          display: flex;
          justify-content: space-around;
          align-items: center;
          box-sizing: border-box;
          margin-bottom: 0.292rem;
          .logo {
            width: 0.38rem;
            height: 0.38rem;
          }
          .plus {
            width: 0.203rem;
            height: 0.203rem;
            margin: 0 0.198rem;
          }
          .you {
            width: 1.104rem;
            height: 0.203rem;
          }
        }
        .sub-tit {
          font-size: 0.25rem;
          color: #222222;
          margin-bottom: 0.182rem;
        }
        .advantage {
          display: flex;
          margin-top: 0.365rem;
          div {
            width: 0.417rem;
            line-height: 0.417rem;
            border-radius: 10px;
            text-align: center;
            font-size: 0.094rem;
            color: #fff;
            margin-right: 0.063rem;
          }
          .time {
            background: linear-gradient(-30deg, #2a2ed8, #3d87f7);
          }
          .labor {
            background: linear-gradient(-30deg, #119a51, #49d88c);
          }
          .worry {
            background: linear-gradient(-30deg, #0a236b, #4e70d2);
          }
          .money {
            background: linear-gradient(-30deg, #f93c06, #da1a1d);
          }
        }
      }
      .table {
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;
        background: url(../assets/image/solution/yon-bg.png);
        background-size: 100% 100%;
        width: 4.833rem;
        height: 3.75rem;
        margin-top: -0.17rem;
        .item-box {
          width: 50%;
          box-sizing: border-box;
          padding: 0.1rem 0.25rem 0.25rem;
          .item-tit {
            font-size: 0.156rem;
            font-weight: 700;
            color: #fa0000;
            margin: 0.078rem 0;
          }
          .item-detail {
            font-size: 0.094rem;
            color: #323232;
            line-height: 0.146rem;
          }
        }
      }
    }
  }
  .value-wrapper {
    width: 100%;
    height: 5.521rem;
    background: url(../assets//image/solution/bottom-bg.jpg);
    background-size: 100% 100%;
    position: relative;
    text-align: center;
    .tit-box {
      position: absolute;
      top: -0.345rem;
      left: calc(50% - 4.08rem);
      width: 8.161rem;
      height: 0.693rem;
      text-align: center;
      border: 2px solid #e50012;
      border-radius: 20px;
      background: #fff;
      box-sizing: border-box;
      .tit {
        width: 1.563rem;
        line-height: 0.375rem;
        color: #fff;
        background: linear-gradient(0deg, #d31222, #ff4301);
        border-radius: 20px;
        position: absolute;
        left: 50%;
        margin-left: -0.781rem;
        top: -0.1875rem;
        font-size: 0.188rem;
      }
      .detail {
        font-size: 0.146rem;
        line-height: 0.693rem;
      }
    }
    .desc {
      font-size: 0.292rem;
      font-weight: 700;
      color: #ffffff;
      line-height: 0.5rem;
    }
  }
}
</style>
