<template>
  <div style="background: white">
    <Header
      :tabbarBoxFixed="tabbarBoxFixed"
      @customChange="customChange"
    ></Header>
    <router-view
      @customChange="customChange"
      :scrollValue="scrollValue"
      :tabbarBoxFixed="tabbarBoxFixed"
    ></router-view>
    <Footer></Footer>
    <!-- 申请 -->
    <el-dialog v-model="dialogVisible" width="100%" :before-close="handleClose">
      <div class="apply-dialog">
        <div class="applyleft">
          <div style="margin: auto 0 auto 1.7rem; textalign: left">
            <div class="tel">致电咨询</div>
            <div class="tel-num">400-650-1132</div>
          </div>
        </div>
        <div class="applyor">OR</div>
        <div class="applyright">
          <div style="margin: auto 0.5rem; textalign: left">
            <div class="tel">让我们联系您</div>

            <div class="public-form">
              <span class="label"
                ><span style="color: red">*</span>您的身份</span
              >
              <label>
                <input
                  type="radio"
                  name="gender"
                  value="0"
                  v-model="identityType"
                />厂家
              </label>
              <label class="radiomargin">
                <input
                  type="radio"
                  name="gender"
                  value="1"
                  v-model="identityType"
                />代理商
              </label>
              <label>
                <input
                  type="radio"
                  name="gender"
                  value="2"
                  v-model="identityType"
                />个人
              </label>
            </div>

            <div class="public-form">
              <span class="label"
                ><span style="color: red">*</span>如何称呼</span
              >
              <input
                class="inputclass"
                type="text"
                name="gender"
                v-model="name"
                placeholder="烦请留下姓名以便我们联系"
              />
            </div>

            <div class="public-form">
              <span class="label"
                ><span style="color: red">*</span>联系电话</span
              >
              <input
                class="inputclass"
                type="text"
                name="gender"
                v-model="phoneNumber"
                placeholder="烦请留下电话以便我们联系"
              />
            </div>

            <div class="public-form">
              <span class="label"
                ><span style="color: red">*</span>您在哪里</span
              >
              <input
                class="inputclass"
                type="text"
                name="gender"
                v-model="addr"
                placeholder="情报诉我们您在哪里"
              />
            </div>
            <el-button
              type="primary"
              color="#2a43f0"
              round
              class="btn-style"
              @click="onsubmit"
              >提交信息</el-button
            >
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import axios from "axios";
import "@/utils/flexible.js";
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
import { reactive, ref, provide } from "vue";
import "./assets/style/common.scss";
import { ElMessage } from 'element-plus'
export default {
  name: "App",
  components: {
    Header,
    Footer,
  },
  setup() {
    var tabbarBoxFixed = ref(false);
    var dialogVisible = ref(false);

    var phoneNumber = ref("");
    var identityType = ref("0");
    var name = ref("");
    var addr = ref("");

    var scrollValue = ref(0);
    const handleScroll = () => {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      scrollValue.value = scrollTop;
      if (scrollTop > 90) {
        tabbarBoxFixed.value = true;
      } else {
        tabbarBoxFixed.value = false;
        console.log("当前滚动条位置:", tabbarBoxFixed.value);
      }
    };
    window.addEventListener("scroll", handleScroll, true);
    const customChange = () => {
      console.log("传参到了:", 11111);
      dialogVisible.value = true;
    };
    
    const onsubmit = () => {
      if( phoneNumber.value == '' || name.value == '' || addr.value == '') {
               ElMessage.error('请补全联系方式')
               return false
    }
      let data = {
        phoneNumber: phoneNumber.value,
        identityType: identityType.value,
        name: name.value,
        addr: addr.value,
      };
      axios
        .post("https://ams.e-next.cn/api/sys/intentionalConsultation/add", data)
        .then((res) => {
          console.log('res:::',res)
          if (res.data && res.data.code == 200) {
            ElMessage({
              message: "提交成功，稍后我们工作人员会联系您",
              type: "success",
            });
      dialogVisible.value = false;
          } else {
               ElMessage.error('申请失败请重试 或 拨打联系电话')
          }
        });
    };

    return {
      handleScroll,
      customChange,
      onsubmit,
      tabbarBoxFixed,
      dialogVisible,
      scrollValue,
      phoneNumber,
      identityType,
      name,
      addr,
    };
  },
};
</script>
<style lang="scss" scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
}
.apply-dialog {
  width: 80%;
  height: 3rem;
  margin: auto;
  background: white;
  display: flex;
}
.applyleft {
  flex: 1;
  display: flex;
}
.applyright {
  flex: 1;
  display: flex;
}
.applyor {
  margin: auto;
  font-size: 0.15rem;
  color: black;
  font-family: pingfangB;
}
.tel {
  color: #2924cf;
  font-size: 0.13rem;
  font-family: pingfang;
}
.tel-num {
  font-size: 0.2rem;
  color: black;
  font-family: pingfangB;
}
.public-form {
  margin-top: 0.2rem;
  .label {
    margin-right: 0.1rem;
    // font-size: 0.08rem;
  }
  .radiomargin {
    margin: 0 0.2rem;
  }
  .inputclass {
    width: 1.3rem;
    height: 25px;
    line-height: 25px;
    border: 1px solid #949bb3;
    border-radius: 5px;
    outline: none;
    color: #2924cf;
    padding-left: 10px;
  }
  input[type="text"]::placeholder {
    color: #c0ccf5;
  }
}
.btn-style {
  padding: 0.08rem 0.12rem !important;
  margin-top: 0.2rem;
  font-size: 0.08rem;
  box-sizing: border-box;
}
</style>
